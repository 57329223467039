.procedures-minHeight {
  min-height: 328px !important;
}

.procedures-header2 {
  width: 33.33% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;

  padding: 16px 12px 4px 60px !important;

  border-top: none !important;
}

.procedures-header3 {
  width: 33.33% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;

  border-top: none !important;
  padding: 16px 12px 4px 40px !important;
}

.procedures-cell1 {
  border-top: none !important;
  vertical-align: middle !important;
  padding: 16px 12px 16px 20px !important;
  word-break: break-word !important;
}

.procedures-cell2 {
  border-top: none !important;

  vertical-align: middle !important;
  padding: 16px 12px 16px 60px !important;
}

.procedures-cell3 {
  border-top: none !important;
  vertical-align: middle !important;
  padding: 16px 12px 16px 40px !important;
}

.procedures-scheduled-header2 {
  width: 33.33% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  text-align: left !important;
  padding: 16px 12px 4px 100px !important;
  border-top: none !important;
}

.procedures-scheduled-header3 {
  width: 33.33% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  text-align: left !important;
  border-top: none !important;
  padding: 16px 12px 4px 100px !important;
}

.procedures-scheduled-cell1 {
  border-top: none !important;
  vertical-align: middle !important;
  padding: 16px 12px 16px 20px !important;
  text-overflow: ellipsis !important;
}

.procedures-scheduled-cell2 {
  border-top: none !important;
  text-align: left !important;
  vertical-align: middle !important;
  padding: 16px 12px 16px 100px !important;
}

.procedures-scheduled-cell3 {
  border-top: none !important;
  text-align: left !important;
  vertical-align: middle !important;
  padding: 16px 12px 16px 100px !important;
}

.view-cell-class {
  border-top: none !important;
  text-align: left !important;
  vertical-align: middle !important;
  padding: 16px 12px 16px 8px !important;
}

.horizontal-line {
  border: 0.1px solid #aeb0b5 !important;
  background: #aeb0b5 !important;
  margin-left: 68px;
  margin-right: 68px;
}

.title-add-procedure {
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #455674;
  margin-bottom: 24px !important;
}

.image-upload-procedure {
  display: block;
  margin: 47px auto 9px auto;
}

.image-upload-procedure1 {
  display: block;
  margin: 26px auto 9px auto;
}
.procedure-image-card {
  width: 140px;
  height: 140px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 20px !important;
}

.procedure-image-wrapper {
  margin: 10px 10px 10px 10px !important;
  width: 140px;
  height: 140px;
  border-radius: 8px;
  background: #e1e7ee;
  object-fit: cover;
}

.procedure-image-wrapper1 {
  /* margin: 10px 10px 10px 10px !important; */
  width: 140px;
  height: 140px;
  border-radius: 8px;
  background: #e1e7ee;
  object-fit: cover;
}

.glossary-image-wrapper {
  margin: 10px 20px 10px 0px !important;
  width: 100px !important;
  height: 100px !important;
  border-radius: 8px;
  background: #e1e7ee;
  object-fit: cover;
  background-image: url("../../../assets/images/uploadAudio.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
}

.procedure-hospital-icon {
  width: 31px;
  height: 31px;
  background: #ffffff !important;
  border-radius: 8px;
  border: none;
  margin-top: -50px;
  margin-left: 107px;
  position: absolute;
}

.glossary-hospital-icon {
  width: 31px;
  height: 31px;
  background: #ffffff !important;
  border-radius: 8px;
  border: none;
  margin-top: -50px;
  margin-left: 60px;
  position: absolute;
}

.procedure-video-icon {
  width: 31px;
  height: 31px;
  background: #ffffff !important;
  border-radius: 8px;
  border: none;
  margin-top: -50px;
  margin-left: 115px;
  position: absolute;
}

.health-hero-video-icon {
  width: 31px;
  height: 31px;
  background: #ffffff !important;
  border-radius: 8px;
  border: none;
  margin-top: -46px;
  margin-left: 106px;
  position: absolute;
}

.procedure-content-label {
  width: 43px !important;
  height: 21px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  white-space: pre !important;
  color: #70b8ff !important;
  display: flex !important;
  margin-top: 24px !important;
}

.textfield-content-label {
  width: 43px !important;
  height: 21px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  white-space: pre !important;
  color: #70b8ff !important;
  display: flex !important;
  margin-top: 24px !important;
}

.textfield-content-label-glossary {
  width: 43px !important;
  height: 21px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  white-space: pre !important;
  color: #70b8ff !important;
  display: flex !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-bottom: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 3.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  right: 50px !important;
}

.scheduled-publish {
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #455674;
  white-space: pre;
  margin-left: 50px;
  margin-bottom: 0px;
}

.procedure-button {
  width: 202px;
  height: 48px;
  background: #455674;
  border-radius: 8px;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f2f8fc;
  margin-top: 22px !important;
}

.podcast-button {
  width: 202px;
  height: 48px;
  background: #455674;
  border-radius: 8px;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f2f8fc;
  margin-top: 22px !important;
  float: right !important;
}

.audio-content-error {
  border: 1px solid #eb5757 !important;
}

.spinner-class {
  margin-left: 35% !important;
  margin-top: 35px !important;
}

@media screen and (max-width:768px){
  .procedures-header2 {
    padding: 16px 12px 4px 10px !important;
  }
  .procedures-header3 {
    padding: 16px 12px 4px 10px !important;
  }
  .procedures-cell3 {
    padding: 16px 12px 16px 0px !important;
  }
  .procedures-cell2 {
    padding: 16px 12px 16px 10px !important;
  }
}