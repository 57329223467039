.head-nav {
  display: flex;
  background: #d6d7d9;
  margin: 16px 16px 20px 16px;
}
.nav-content {
  width: 209px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5b616b;
  margin: 0%;
  cursor: pointer;
}

.active-content {
  background: #455674;
  color: #f2f8fc;
}

.my-table-head {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #70b8ff;
  border: none !important;
}

.work-progress {
  display: flex !important;
  justify-content: center !important;
  color: #455674;
  margin-top: 20%;
}

.content-disable {
  display: none;
}

.content-active {
  display: block;
}

@media screen and (max-width:768px){
  .nav-content {
    font-size: 11px !important;
  }
}