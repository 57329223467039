.my-footer {
  width: 100%;
  margin-top: 1rem;
  bottom: 0 !important;
  text-align: center;
}
.my-footer1 {
  width: 100%;
  margin-top: 16rem;
  bottom: 0;
  text-align: center;
}

.my-footer p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #aeb0b5;
  bottom: 0;
}

.my-footer1 p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #aeb0b5;
  bottom: 0;
}
.user-management-footer {
  width: 100%;
  margin-top: 1rem;
  bottom: 0;
  text-align: center;
}
.user-management-footer p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #aeb0b5;
  bottom: 0;
  margin-top: 20px !important;
  width: 100%;
}
.content-management-footer {
  width: 100%;
  margin-top: 1rem;
  bottom: 0;
  text-align: center;
}
.content-management-footer p {
  margin-top: 50px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #aeb0b5;
  bottom: 0;
  width: 100%;
}
.parent-child-footer {
  width: 100%;
  margin-top: 1rem;
  bottom: 0;
  text-align: center;
}
.parent-child-footer p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #aeb0b5;
  bottom: 0 !important;
  position: absolute;
  width: 100%;
  margin-bottom: -10px !important;
}
.cls-footer {
  width: 100%;
  margin-top: 1rem;
  bottom: 0;
  text-align: center;
}
.cls-footer p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #aeb0b5;
  bottom: 0 !important;
  position: absolute;
  width: 100%;
  margin-bottom: -66px !important;
  padding-bottom: 17px !important;
}

.dashboard-footer {
  width: 100%;
  margin-top: 14rem;
  bottom: 0 !important;
  text-align: center;
}

.dashboard-footer p {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #aeb0b5;
  bottom: 0;
}
