.back-nav {
  padding: 30px 2px 20px 0px !important;
}

.back-nav-item {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #5b616b !important;
}

.back-icon {
  margin: 0 8px 0 17px;
}

.edit-button {
  margin-right: 20px;
  margin-left: 20px;
  width: 141px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #70b8ff;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #70b8ff;
}

.edit-hero-button {
  margin-right: 20px;
  width: 202px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #70b8ff;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #70b8ff;
}

.delete-button {
  border: none;
  width: 141px;
  height: 37px;
  background: #ff6750;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
}

.delete-hero-button {
  border: none;
  width: 202px;
  height: 37px;
  background: #ff6750;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
}

.save-button {
  background: #455674;
  border-radius: 8px;
  width: 134px;
  height: 37px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #f2f8fc;
  border: none;
}

.parent-back-icon {
  margin-right: 8px;
}
.parent-back-nav {
  white-space: pre !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #5b616b !important;
  cursor: pointer !important;
}
.parent-back {
  padding-top: 30px !important;
  padding-left: 0px !important;
}

.title-bar-heading {
  font-family: "poppins", sans-serif;
  font-style: normal;
  margin-bottom: 18px !important;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 27px !important;
  color: #455674 !important;
}

.title-bar-heading2 {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 30px !important;
  color: #455674 !important;
}

.scroll-icon {
  margin: 0px 0px 20px 20px;
}
.scroll-icon1 {
  margin: 0px 20px 20px 0px;
}

/* button classes */

.form-save-and-add-button {
  width: 202px;
  height: 48px;
  background: #ffffff !important;
  border: 1px solid #455674 !important;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #455674;
  margin-right: 20px;
}

.form-save-and-copy-button {
  width: 417px;
  height: 48px;
  background: #ffffff !important;
  border: 1px solid #455674 !important;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #455674;
  margin-right: 10px;
}

.form-save-and-add-button1 {
  width: 202px;
  height: 48px;
  background: #ecebeb !important;
  border: 1px solid #455674 !important;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #455674;
  margin-right: 20px;
}

.form-delete-button {
  width: 202px;
  height: 48px;
  background: #ff6750 !important;
  border: 1px solid #ff6750 !important;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-right: 20px;
}

.form-save-button {
  border: none;
  width: 202px;
  height: 48px;
  background: #455674;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f2f8fc;
}

.apply-filter-button {
  width: 202px;
  height: 48px;
  background: #455674;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f2f8fc;
  border: none;
}

.clear-filter-button {
  margin: 12px 12px 0 0;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  color: #ff6750;
}

.form-check-label {
  margin-right: 16px;
}

.filter-check {
  margin-right: 1.75rem !important;
  margin-bottom: 0.75rem !important;
}

#tiers {
  height: 15px;
  width: 22px;
}

#dateAdded {
  height: 15px;
  width: 22px;
}
