.my-card-cm {
  max-height: 144px;
  height: 144px !important;
  width: 100% !important;
  max-width: 335px;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  border: none !important;
  margin: 0px !important;
}

.my-card-empty {
  max-height: 144px;
  height: 144px !important;
  width: 100% !important;
  max-width: 335px;
  background-color: none !important;
  border-radius: 8px !important;
  border: none !important;
  margin: 0px !important;
}

.view-more-card {
  width: 133px;
  height: 37px;
  background: #ffffff !important;
  margin-top: 33px !important;
  border: none !important;
  border-radius: 8px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #70b8ff;
}

.view-more-card1 {
  width: 133px;
  height: 37px;
  background: #ffffff !important;
  margin-top: 6px !important;
  border: none !important;
  border-radius: 8px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #70b8ff;
}

.card-title-content {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-align: center !important;
  text-transform: none !important;
  color: #455674 !important;
}

.search-div{
  height: 450px;
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  z-index: 1;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  background: #f4f4f4;
  border-radius: 5px;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #455674;
  width: 120px;
  height: 120px;
  margin-top: 150px;
  margin-left: 440px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-data {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 36px;
  color: #455674;
  margin-top: 12rem;
  margin-left: 24rem;
}

.search-data {
  font-family: "poppins", sans-serif;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 200;
  cursor: pointer;
}

@media screen and (max-width:768px){
  .card-title-content {
    font-size: 15px !important;
  }
}