.home-navbar {
  margin-top: 20px;
}
#nav-brand {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #455674;
}

.nav-link {
  display: block;
  padding: 0.1rem 0.5rem;
  color: black;
}

.nav-dropdowns {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #70b8ff !important;
}

.dropdown-menu.show {
  background: #ffffff;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

#collasible-nav-dropdown::after {
  display: none;
}
