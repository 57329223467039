.subscription-heading {
  width: 1.33% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  text-align: left !important;
  padding: 16px 12px 4px 10px !important;
  border-top: none !important;
  border-bottom: none !important;
}