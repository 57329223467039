.verifyOTP-input-field {
  height: 60px !important;
  width: 100% !important;
  max-width: 60px;
  background-color: #f1f1f1 !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: none !important;
}
.focus-css {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
  outline: none !important;
  border: none !important;
}
.separator-css {
  width: 2.3vh;
}
.verify-otp-row-container {
  margin: 39px 25% -10px 26% !important;
}

.verifyOTP-form-main-css {
  width: 100%;
  height: 100vh;
}
.verifyOTP-welcome-label-css {
  padding-top: 203px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #5b616b;
  text-align: center;
  display: block;
  margin: 0px;
}
.verifyOTP-message-label-css {
  padding-top: 0px;
  margin-top: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: #aeb0b5;
  display: block;
  text-align: center;
}

.invalid-label {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px;
  color: #d9534f;
  width: 50% !important;
  padding-left: 23px !important;
  margin: 20px 25% -12px 23% !important;
}
.verifyOTP-invalid-label {
  border: 1px solid #eb5757 !important;

  border-radius: 8px !important;
}
