.content-close-icon {
  float: right;
  margin-right: 6px !important;
  cursor: pointer;
}
.content-title-modal {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-top: 0px !important;
  color: #455674 !important;
}
.text-field-content {
  background: #f4f4f4 !important;
  border-radius: 8px !important;
  height: 100px !important;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: block !important;
  color: #aeb0b5 !important;
  padding-top: 20px !important;
  padding-left: 20px !important;
}
.input-field-content {
  background: #f4f4f4 !important;
  border-radius: 8px !important;
  height: 60px !important;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: block !important;
  color: #aeb0b5 !important;
  padding-left: 20px !important;
}
.input-date-field-content {
  background: #f4f4f4 !important;
  border-radius: 8px !important;
  height: 60px !important;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #aeb0b5 !important;
  padding-left: 20px !important;
  margin: 0px !important;
  width: 100% !important;
  outline: none !important;
}
.input-date-field-content1 {
  background: #f4f4f4 !important;
  border-radius: 8px !important;
  height: 60px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #aeb0b5 !important;
  padding-left: 20px !important;
  margin: 0px !important;
  width: 100% !important;
  border: 1px solid #eb5757 !important;
  outline: none !important;
}
.input-date-field-content:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}
.input-date-field-content1:focus {
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
  max-width: 265px !important;
}
.react-datepicker__input-container {
  width: 100% !important;
  max-width: 265px !important;
}

.modal-content-label {
  width: 43px !important;
  height: 21px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  white-space: pre !important;
  color: #70b8ff !important;
  display: flex !important;
}
.modal-content-quotes {
  width: 600px !important;
  margin: auto !important;
}
.modal-content-body {
  padding: 24px !important;
}
.modal-content-header {
  margin-top: 24px !important;
  border: 0.2px solid #aeb0b5 !important;
  background: #aeb0b5 !important;
}
.content-save-button {
  margin-top: 23px !important;
  float: right;
  width: 145px;
  height: 45px;
  background: #455674;
  border-radius: 8px;
  border: none;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #f2f8fc;
}

.input-field-content::-webkit-input-placeholder {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: block !important;
  color: #aeb0b5 !important;
}

.input-field-content::-moz-placeholder {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #aeb0b5 !important;
  opacity: 1 !important;
}
.input-field-content::-moz-placeholder-shown {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #aeb0b5 !important;
  opacity: 1 !important;
}
.input-field-content:-ms-input-placeholder {
  /* font-family: "poppins", sans-serif !important; */
  /* IE 10+ */
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #455674 !important;
}

.text-field-content::-webkit-input-placeholder {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: block !important;
  color: #aeb0b5 !important;
}

.text-field-content::-moz-placeholder {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #aeb0b5 !important;
  opacity: 1 !important;
}
.text-field-content::-moz-placeholder-shown {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #aeb0b5 !important;
  opacity: 1 !important;
}
.text-field-content:-ms-input-placeholder {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #455674 !important;
}
.min-height-class {
  min-height: 390px !important;
}

.chracter-remain {
  margin-top: -10px;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  color: #aeb0b5;
  float: right;
}

.chracter-remain-title {
  margin-top: 5px;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  color: #aeb0b5;
  float: right;
}
