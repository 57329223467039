.login-main {
  width: 100%;
  height: 100vh;
}
#formBasicPassword {
  background-color: #f4f4f4 !important;
}

.login-welcome {
  padding-top: 203px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 30px;
  line-height: 45px;
  color: #5b616b;
  text-align: center;
  display: block;
  margin: 0px;
}
.login-message {
  padding-top: 0px;
  margin-top: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: #aeb0b5;
  display: block;
  text-align: center;
}
.login-forgot {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px;
  display: block;
  align-items: center;
  margin-right: 25%;
  float: right;
  color: #aeb0b5 !important;
  margin-bottom: 7px;
  margin-top: 12px;
  width: 50%;
  max-width: 125px !important;
  cursor: pointer;
}

.eye-icon-css {
  margin-left: 70%;
  margin-right: 60%;
  margin-top: -34px;
  position: absolute;
  z-index: 2;
}
.eye-icon-off-css {
  margin-left: 70%;
  margin-right: 60%;
  margin-top: -35.5px;
  position: absolute;
  z-index: 2;
}

.login-email {
  margin-top: 40px !important;
  width: 50% !important;
  margin-left: 25% !important;
  margin-right: 25% !important;
  padding-left: 20px !important;
  height: 60px !important;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: none !important;
  background-image: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5b616b !important;
}
.login-input-invalid {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px;
  color: #d9534f;
  width: 50% !important;
  padding-left: 20px !important;
  margin: 5px 25% -9px 23% !important;
}
.login-password-invalid {
  margin-top: 5px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px;
  color: #d9534f;
  width: 50% !important;
  margin-left: 23% !important;
  margin-right: 25% !important;
  padding-left: 20px !important;
}
.login-password {
  width: 50% !important;
  margin-top: 20px !important;
  margin-left: 25% !important;
  margin-right: 25% !important;
  padding-left: 20px !important;
  height: 60px !important;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: none !important;
  position: relative;
  background-image: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5b616b !important;
}

.login-for-superuser-css {
  text-align: center;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #ff0000;
  margin-top: 30px;
}
