.my-card {
  height: 171px;
  width: 335px;
  margin: 20px;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  border: none !important;
}

.card-title {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: capitalize;
  color: #455674;
}

.card-subtitle {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #aeb0b5;
}

button#view-more {
  background-color: #ffffff;
  height: 37px;
  width: 133px;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #70b8ff;
  margin: 40px 0px 8px 77px;
}

@media screen and (max-width:768px){
  .my-card {
    width: 245px;
    margin: 10px;
  }
  .card-title{
    font-size: 15px;
  }
  button#view-more {
  margin: 10px 0px 12px 40px;
  }
}
@media screen and (max-width:991px){
  .my-card {
    width: 295px;
    margin: 6px;
  }
  button#view-more {
    margin: 10px 0px 12px 55px;
    }
}
@media screen and (max-width:425px){
  .my-card {
    width: 390px;
    margin: 6px;
  }
  button#view-more {
    margin: 30px 0px 12px 100px;
    }
}