.common-image-component {
  height: 100vh;
  width: 100%;
}
#common-button-css {
  width: 50% !important;
  height: 48px !important;
  margin-left: 25% !important;
  margin-right: 25% !important;
  background: #455674;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 92px;
  margin-top: 17px;
  border: none !important;
}

#common-button-value-css {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: block;
  align-items: center;
  text-align: center;
  color: #f2f8fc;
}
.field-icon {
  color: #b6cde5;
}
.common-logo-container {
  position: relative;
  left: 0;
  right: 0;
}
.common-logo-css {
  position: absolute;
  left: 29%;
  right: 30%;
  top: 44%;
  bottom: 25%;
  max-width: 50%;
}

.table-row {
  border-bottom: 0.5px solid #d6d7d9 !important;
}
.table-main {
  background: #e5e5e5 !important;
  border-radius: 8px !important;
  margin-top: 44px !important;
  width: 100% !important;
  max-width: 1046px !important;
  max-height: 486px !important;
  margin-bottom: 0px !important;
}
.tableHead {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #455674;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: -30px;
  border-bottom: 4px solid #455674 !important;
  width: 10%;
  max-width: 107px !important;
  cursor: pointer;
}
.tableHead-noBorder {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #455674;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: -30px;
  border-bottom: 1px solid #aeb0b5 !important;
  width: 10%;
  max-width: 107px !important;
  cursor: pointer;
}
.cls-tablehead {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #455674;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: -30px;
  border-bottom: 4px solid #455674 !important;
  width: 10%;
  max-width: 107px !important;
  cursor: pointer;
}
.cls-tablehead-noBorder {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #455674;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: -30px;
  width: 10%;
  max-width: 107px !important;
  border-bottom: 1px solid #aeb0b5 !important;
  cursor: pointer;
}
.clinician-tablehead {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #455674;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: -30px;
  border-bottom: 4px solid #455674 !important;
  width: 10%;
  max-width: 107px !important;
  cursor: pointer;
}
.clinician-tablehead-noBorder {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #455674;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: -30px;
  width: 10%;
  max-width: 107px !important;
  border-bottom: 1px solid #aeb0b5 !important;
  cursor: pointer;
}
.table-header1 {
  width: 33.33% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  text-align: left;
  padding-left: 40px !important;
  padding-bottom: 0px !important;
}
.table-header2 {
  width: 33.33% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  padding-left: 95px !important;
  padding-bottom: 0px !important;
}
.table-header3 {
  width: 33.33% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  padding-left: 95px !important;
  padding-bottom: 0px !important;
}
.table-cell {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #455674;
  border-bottom: 0.5px solid #d6d7d9;
  text-align: left;
  padding: 24px 0px 24px 40px !important;
}
.table-cell2 {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #455674;
  border-bottom: 0.5px solid #d6d7d9;
  text-align: left;
  max-width: 250px;
  overflow: hidden;
  padding: 24px 0px 24px 95px !important;
}
.table-cell-view {
  padding: 16px 0px 16px 40px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #455674;
  border-bottom: 0.5px solid #d6d7d9;
  text-align: right;
}

#view {
  border: none;
  width: 94px;
  height: 37px;
  background: #ffffff;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ff6750;
  margin-right: 89px;
}
.pagination-container {
  display: flex;
  height: 29px;
  list-style: none;
  width: 100%;
  justify-content: flex-end;
  padding-right: 0px !important;
  margin-top: 20px !important;
}
.pagination-active {
  max-width: 29px !important;
  max-height: 29px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  padding-left: 12px !important;
  padding-top: 5px !important;
  padding-right: 12px !important;
  padding-bottom: 5px !important;
  color: #4f5764 !important;
  background: #f1f1f1;
  border-radius: 4px;
}
.page-link-className-css {
  padding-left: 15px !important;
  padding-right: 15px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #aeb0b5;
}
.pagination-previous {
  padding-right: 18px !important;
  width: 32px;
  height: 21px;

  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  text-align: center;

  color: #455674 !important;
}
.pagination-next {
  padding-left: 15px !important;
  width: 32px;
  height: 21px;

  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  text-align: center;

  color: #455674 !important;
}
.ellipsis-css {
  display: none !important;
}
.pagination-link-disabled a {
  width: 31px;
  height: 21px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #aeb0b5 !important;
}

.spinner-pos {
  position: fixed;
  top: 50vh;
  left: 46vw;
  pointer-events: none;
  opacity: 0.8 !important;
}
.no-results {
  margin-left: 375px !important;
  margin-right: -500px !important;
  margin-top: 160px !important;
  max-width: 0px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 21px;
  text-align: center;
  color: #aeb0b5 !important;
  white-space: nowrap;
  display: block !important;
  justify-content: end !important;
}
.close-icon-delete {
  cursor: pointer;
}

.spinner-class-video {
  margin-top: 38% !important;
  margin-left: 38% !important;
}

.error-boundary-css {
  margin: 127px auto 0px auto;
  display: block;
}

.error-boundary-text {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center !important;
  color: #455674;
  margin-top: 32px;
}
.error-boundary-text1 {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 27px;
  text-align: center !important;
  color: #5b616b;
  margin-top: 8px;
}

.error-boundary-button {
  width: 145px;
  height: 45px;
  background: #455674;
  border-radius: 8px;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f2f8fc;
  margin: 24px auto 0px auto;
  display: block;
}

@media screen and (max-width:768px){
  .no-results {
    margin-left: 220px !important;
  }
}