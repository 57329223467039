.main-navbar {
  margin-top: 0px !important;
  margin-bottom: -12px !important;
}

#SubscriberManagementTitle-css {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #455674;
}
#search-box::placeholder {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #aeb0b5;
}

#my-button {
  border: none;
  width: 188px;
  height: 37px;
  background: #455674;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
}

#collasible-nav-dropdown {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

#collasible-nav-dropdown::after {
  display: none;
}

#navbar-filter {
  padding-right: 20px !important;
  padding-left: 20px !important;
  cursor: pointer;
}
#navbar-filter::after {
  display: none !important;
}
.dropdown-item {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

#parent-navbar {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #455674;
  align-items: center;
}
.navbar-class {
  margin-top: 0px !important;
  margin-bottom: -12px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  max-height: 62px !important;
}

.search-classname-css {
  background: #f4f4f4 !important;
  width: 320px !important;
  height: 37px !important;
  border-radius: 8px !important;
  background-image: url("../../assets/images/globalSearchIconUnique.svg") !important;
  background-repeat: no-repeat !important;
  text-indent: 50px !important;
  background-position: 7% 50% !important;
  border: none !important;
  outline: none !important;
  font-family: "poppins", sans-serif !important;
  font-size: 15px !important;
  margin-right: 0px !important;
  color: #5b616b !important;
}

.dropdown-toggle::after {
  display: none !important;
}

@media screen and (max-width:768px){
  #SubscriberManagementTitle-css {
    font-size: 17px;
  }
  .search-classname-css {
    width: 250px !important;
  }
  #navbar-filter {
    padding-right: 17px !important;
    padding-left: 17px !important;
    cursor: pointer;
  }
  #my-button {
    width : 138px;
  }
  
}