.parent-name-field {
  width: 100% !important;
  max-width: 424px !important;
  height: 60px !important;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: none !important;
  background-image: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5b616b !important;
}
.parent-name {
  width: 43px !important;
  height: 21px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  white-space: pre !important;
  color: #70b8ff !important;
}
.parent-name-field::-webkit-input-placeholder {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #455674 !important;
}

.parent-name-field::-moz-placeholder {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #455674 !important;
  opacity: 1 !important;
}
.parent-name-field::-moz-placeholder-shown {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #455674 !important;
  opacity: 1 !important;
}
.parent-name-field:-ms-input-placeholder {
  /* font-family: "poppins", sans-serif !important; */
  /* IE 10+ */
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #455674 !important;
}
.child-multi {
  padding-left: 2px !important;
  max-width: 135px !important;
  max-height: 110px !important;
  height: 110px !important;
  border-radius: 50% !important;
  background-color: white !important;
}

.parent-delete-header {
  border-bottom: none !important;
  justify-content: flex-end !important;
  padding: 24px 25px 8px 25px !important;
}
.modal-content-delete {
  width: 600px !important;
  height: 382px !important;
  margin: auto !important;
}

.parent-modal-title {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  margin-top: 29.44px !important;
  text-align: center;

  color: #455674;
}
.modal-parent-text {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin: 8px 50px !important;
  text-align: center !important;
  padding: 0px !important;
  max-width: 480px !important;
  color: #5b616b !important;
}
.back-button-modal {
  border: 1px solid #455674 !important;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  width: 202px;
  height: 48px;
  margin-right: 20px !important;
  margin-top: 18px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #455674;
}
.delete-button-modal {
  background: #ff6750;
  border-radius: 8px;
  margin-top: 18px !important;
  border: none;
  width: 202px;
  height: 48px;
  margin-left: 20px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f2f8fc;
}
.close {
  color: #ff6750 !important;
}
