.password-email {
  margin: 40px 25% -9px 25% !important;
  width: 50% !important;
  padding-left: 20px !important;
  height: 60px !important;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: none !important;
  background-image: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5b616b !important;
}
.form-control.is-invalid {
  border: 1px solid #eb5757 !important;
}
.password-invalid-email {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px;
  color: #d9534f;
  width: 50% !important;
  margin-left: 23% !important;
  margin-right: 25% !important;
  padding-top: 10px;
  padding-left: 20px !important;
  margin-bottom: -9px;
}
.password-main {
  width: 100%;
  height: 100vh;
}
.password-label {
  padding-top: 203px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #5b616b;
  text-align: center;
  display: block;
  margin: 0px;
}
.password-label1 {
  padding-top: 0px;
  margin-top: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: #aeb0b5;
  display: block;
  text-align: center;
}
