.table-area {
  background-color: #e5e5e5;
  height: 876px;
  margin: 40px 16px 0px 16px;
  border-radius: 8px;
}

.table-head {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #455674;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  border-bottom: 4px solid #455674 !important;
}

/* Table styling*/
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  padding-right: 40px;
  padding-left: 5%;
}

tr {
  border-bottom: 0.5px solid #d6d7d9 !important;
}

th {
  width: 38%;
  height: 21px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: 0.5px solid #d6d7d9;
}

td {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #455674;
  border-bottom: 0.5px solid #d6d7d9;
}

#detail-view {
  border: none;
  width: 94px;
  height: 37px;
  background: #ffffff;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ff6750;
}

.no-results-found {
  margin-left: 315px !important;
  margin-right: -500px !important;
  margin-top: 160px !important;
  max-width: 0px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 21px;
  text-align: center;
  color: #aeb0b5 !important;
  white-space: nowrap;
  display: block !important;
  justify-content: end !important;
}
/* table style end */

.congrats-message {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #455674;
}

.congrats-para {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  /* UI/Text - Secondary */

  color: #5b616b;
}

.spinner-position {
  padding: 120px 0px 0px 380px;
}

#copy-hospital {
  border: none;
  width: 94px;
  height: 37px;
  background: #ffffff;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #455674;
}