.super-user-checkbox {
  width: 18px !important;
  height: 18px !important;
  margin-left: 24px !important;
  border: red !important;
}

.super-user-checkbox:focus {
  box-shadow: none !important;
}

.date-added {
  width: 18px !important;
  height: 18px !important;
  margin-left: 24px !important;
}

.date-added:focus {
  box-shadow: none !important;
}

.select-hospital-class {
  max-width: 552px !important;
  width: 100% !important;
}

.select-hospital-class__value-container {
  overflow-y: scroll !important;
  -ms-overflow-style: none !important;
  /* Internet Explorer 10+ */
  scrollbar-width: none !important;
  /* Firefox */
  padding-right: 50px !important;
}

.select-hospital-class__value-container::-webkit-scrollbar {
  display: none !important;
  /* Safari and Chrome */
}

.checkbox-name-super-user {
  height: 21px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  white-space: pre !important;
  color: #70b8ff !important;
}

.checkbox-name-date-added {
  height: 21px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  white-space: pre !important;
  color: #70b8ff !important;
  padding-left: 63px !important;
}

.clear-filters {
  color: #ff6750 !important;
  background: none !important;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  float: right;
  margin-right: -50px;
  margin-top: 8px;
}

.apply-filters {
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  float: right;
  text-align: center;
  width: 202px;
  height: 48px;
  color: #f2f8fc;
  background: #455674 !important;
  border: none !important;
  border-radius: 8px;
}

.select-style {
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  padding: 20px 20px 5px 20px !important;
  color: #70b8ff;
}

.option-custom-input {
  float: right !important;
  height: 18px !important;
  width: 18px !important;
  margin-top: 5px !important;
}