.navbar-edit-button {
  margin-right: 20px;
  margin-left: 20px;
  width: 141px;
  height: 37px;
  background: #ffffff;
  border: 1px solid #70b8ff;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #70b8ff;
}
.add-button-css {
  background: #455674;
  border-radius: 8px;
  padding: 0px !important;
  border: none !important;
  width: 170px !important;
  height: 37px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
}
.navbar-delete-button {
  border: none;
  width: 141px;
  height: 37px;
  background: #ff6750;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
}
.navbar-save-button {
  width: 134px;
  height: 37px;
  background: #455674 !important;
  border-radius: 8px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #f2f8fc !important;
  border: none !important;
}

#parent-nav-title {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #455674;
}

.parent-navbar {
  padding: 23px 0px 8px !important;
}
#child-navbar-title {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #455674;
}

.child-navbar-css {
  padding-left: 0px !important;
  padding-top: 60px !important;
}

.hospital-dropdown-container{
  width: 50% !important;
  margin-right: 2%;
}

.csv-button-css {
  background: #455674;
  border-radius: 8px;
  padding: 0px !important;
  border: none !important;
  width: 170px !important;
  height: 30px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.csv-user-button-css {
  background: #455674;
  border-radius: 8px;
  padding: 0px !important;
  border: none !important;
  width: 170px !important;
  height: 35px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.csv-subscription-button-css {
  background: #455674;
  border-radius: 5px;
  padding: 0px !important;
  border: none !important;
  width: 170px !important;
  height: 30px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
  margin-right: 3%;
  margin-top: 1.8%;
}