#input-group-dropdown-2 {
  text-align: end !important;
  margin-left: 309px !important;
  outline: none !important;
  background: none !important;
}
.img-class {
  background-image: url("../../../assets/images/userGenderDropdown.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
}

select::-ms-expand {
  display: none;
}
select {
  -webkit-appearance: none;
  appearance: none;
}
@-moz-document url-prefix() {
  .ui-select {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
  }
  .ui-select select {
    width: 110%;
    background-position: right 30px center !important;
    border: none !important;
  }
}
