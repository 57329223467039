input#file-upload-button {
  position: absolute;
  width: 424px;
  height: 100px;
  background: #b6cde5;
  opacity: 0.2;
  border-radius: 8px;
}

input#file-upload-button1 {
  position: absolute;
  width: 424px;
  height: 10px;
  background: #b6cde5;
  opacity: 0.2;
  border-radius: 8px;
}

.hospital-image-wrapper {
  width: 140px;
  height: 140px;
  border-radius: 8px;
  margin: 0px 10px 16px 10px;
  background: #e1e7ee;
}

.hospital-image-card {
  width: 140px;
  height: 140px;
  border-radius: 8px;
  object-fit: cover;
}

.delete-hospital-icon {
  width: 31px;
  height: 31px;
  background: #ffffff !important;
  border-radius: 8px;
  border: none;
  position: absolute;
  margin-top: 98px;
  margin-left: 98px;
}

.user-card {
  width: 101px;
  height: 148px;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 14px;
}

.hero-card:hover {
  cursor: pointer;
}

.user-image {
  width: 101px;
  height: 101px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #e8e8e8;
}

.user-name {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 2px;
  color: #455674;
}

.user-role {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #5b616b;
}

.hospital-name {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 2px;
  color: #455674;
}

.hospital-address {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  color: #aeb0b5;
}

.hospital-logo {
  width: 424px;
  height: 100px;
  opacity: 1.3 !important;
  border-radius: 8px;
  margin-left: 70px;
  object-fit: contain;
}
.hospital-logo1 {
  width: 424px;
  height: 100px;
  background: #b6cde5;
  opacity: 1.3 !important;
  border-radius: 8px;
  margin-left: 70px;
  object-fit: contain;
}

.plan-card {
  width: 424px;
  height: 97px;

  background: #f4f4f4;
  border-radius: 8px;
}

.plan-duration {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #455674;
  padding: 16px 0px 0px 20px;
}

.plan-date {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ff6750;
  padding: 0px 0px 16px 20px;
}

.renew-btn {
  float: right;
  width: 113px;
  height: 37px;
  margin-top: 30px !important;
  margin-right: 20px !important;
  background: #70b8ff;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
  border: none;
}

.download-btn {
  float: right;
  width: 113px;
  height: 37px;
  background: #70b8ff;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
  border: none;
}

.hospital-image-video {
  width: 246px;
  height: 140px;
  border-radius: 8px;
  margin-left: 20px;
  margin-bottom: 40px;
  object-fit: cover;
}

.upload-logo {
  width: 424px;
  height: 100px;
  background: #e1e7ee;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin-left: 14%;
  margin-top: 30px;
}

.image-row {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overflow: hidden;
  flex-wrap: nowrap !important;
}

.upload-hospital-image-icon {
  padding: 40px 0px 70px 0px;
}

.upload-label {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding-left: 39px;
  color: #455674;
}

.upload-label-logo {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #455674;
  padding-top: 37px;
  cursor: pointer;
}

.edit-hospital-user {
  padding-left: 20px;
  margin-top: 16px;
}

.modal-select-info {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  color: #70b8ff;
}

.modal-note-text {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 34px;
  text-align: center;
  color: #808080;
}

.form-check-label {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #5b616b;
}

.choose-user-form {
  display: flex;
  justify-content: center;
  gap: 60px;
  margin-top: 27px;
  margin-bottom: 28px;
}

.add-user-logo {
  margin: 50px 0px 28px 202px;
}

.save-hospital-logo {
  width: 90px;
  height: 90px;
  margin: 10px 0px 14px 183px;
}

.common-container {
  min-height: 100vh !important;
  position: relative !important;
  max-width: 1046px !important;
}

.after-upload-logo {
  width: 424px;
  height: 100px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin-left: 14%;
  margin-top: 30px;
  object-fit: contain;
}

.modal-delete-button {
  width: 202px;
  height: 48px;
  background: #ff6750;
  border-radius: 8px;
  border: none;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f2f8fc;
}

.profile-image-view {
  width: 100px;
  height: 100px;
  background: #e8e8e8;
  border-radius: 50px;
  cursor: pointer;
}

.blank-user-image {
  background-image: url("../../../assets/images/circlePhoto.svg");
  background-repeat: no-repeat !important;
  border-radius: 50% !important;
  width: 100px !important;
  height: 100px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  padding-top: 23px !important;
  text-align: center !important;
  color: #455674;
}

#formBasicSuperuser {
  height: 15px;
  width: 15px;
}

/* to solve the safari issue */
input[type="date"] {
  display: block;
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  min-height: 1.2em;
}
.hospital-iamge-view {
  margin: 10px 10px 10px 10px !important;
  width: 140px;
  height: 140px;
  border-radius: 8px;
  background: #e1e7ee;
  object-fit: cover;
  cursor: pointer;
}

.hospital-edit-media-view {
  margin: 10px 10px 10px 21px !important;
  width: 140px;
  height: 140px;
  border-radius: 8px;
  background: #e1e7ee;
  object-fit: cover;
}

.hospital-video-delete-button {
  width: 31px;
  height: 31px;
  background: #ffffff !important;
  border-radius: 8px;
  border: none;
  margin-top: -50px;
  margin-left: 115px;
  position: absolute;
}
.hospital-image-delete-icon {
  width: 31px;
  height: 31px;
  background: #ffffff !important;
  border-radius: 8px;
  border: none;
  margin-top: -50px;
  margin-left: 107px;
  position: absolute;
}

.hospital-hero-delete-icon {
  width: 31px;
  height: 31px;
  background: #ffffff !important;
  border-radius: 50%;
  border: none;
  position: absolute;
  margin-top: 72px;
  margin-left: 72px;
}

.renew-modal_heading {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #455674;
}

.renew-form-input {
  width: 100% !important;
  height: 60px !important;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: none !important;
  background-image: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5b616b !important;
}

.img-class {
  background-image: url("../../../assets/images/userGenderDropdown.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
}

.common-date-input {
  background: #f4f4f4 !important;
  border-radius: 8px !important;
  height: 60px !important;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #495057 !important;
  padding-left: 20px !important;
  margin: 0px !important;
  width: 424px !important;
  outline: none !important;
}

.common-date-input-error {
  background: #f4f4f4 !important;
  border-radius: 8px !important;
  height: 60px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #495057 !important;
  padding-left: 20px !important;
  margin: 0px !important;
  width: 424px !important;
  border: 1px solid #eb5757 !important;
  outline: none !important;
}
.input-date-field-content-renew {
  background: #f4f4f4 !important;
  border-radius: 8px !important;
  height: 60px !important;
  border: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #495057 !important;
  padding-left: 20px !important;
  margin: 0px !important;
  width: 464px !important;
  outline: none !important;
}

@media screen and (max-width:768px){
  .hospital-address {
    font-size: 14px;
  }
  .user-name {
    overflow: hidden;
  }
}

.modal-save-hospital-button {
  width: 202px;
  height: 48px;
  background: #455674;
  border-radius: 8px;
  border: none;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #f2f8fc;
}