.reset-input {
  margin: 40px 25% -9px 25% !important;
  width: 50% !important;

  padding-left: 20px !important;

  height: 60px !important;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: none !important;
  background-image: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5b616b !important;
}
.reset-invalid-password {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: #d9534f;
  width: 50% !important;
  margin-left: 23% !important;
  margin-right: 25% !important;
  padding-top: 10px;
  padding-left: 20px !important;

  margin-bottom: -9px;
}

.reset-confirm {
  margin: 29px 25% 7px 25% !important;
  width: 50% !important;

  padding-left: 20px !important;

  height: 60px !important;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: none !important;
  background-image: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5b616b !important;
}
.reset-confirm-label {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px;
  color: #d9534f;
  width: 50% !important;
  margin-left: 23% !important;
  margin-right: 25% !important;
  padding-left: 20px !important;

  margin-bottom: -9px;
}
.reset-main {
  width: 100%;
  height: 100vh;
}
.reset-welcome {
  padding-top: 203px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  color: #5b616b;
  text-align: center;
  display: block;
  margin: 0px;
}
.resetPassword-message-label-css {
  padding-top: 0px;
  margin-top: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  color: #aeb0b5;
  display: block;
  text-align: center;
}

.resetPassword-eye-icon-css {
  margin-left: 70%;
  margin-right: 60%;
  margin-top: -26px;
  position: absolute;
  z-index: 2;
}
.resetPassword-eye-icon-off-css {
  margin-left: 70%;
  margin-right: 60%;
  margin-top: -27.5px;
  position: absolute;
  z-index: 2;
}
.resetConfirmPassword-eye-icon-css {
  margin-left: 70%;
  margin-right: 60%;
  margin-top: -41px;
  position: absolute;
  z-index: 2;
}
.resetConfirmPassword-eye-icon-off-css {
  margin-left: 70%;
  margin-right: 60%;
  margin-top: -42.5px;
  position: absolute;
  z-index: 2;
}

.reset-success {
  margin-top: 200px !important;
}
.reset-success-welcome {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 30px !important;
  text-align: center !important;
  display: block;
  margin: 0px;
}
.reset-success-message {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center !important;
}
