.plan-row {
  margin-top: 20px;
  display: flex;
  margin-left: 6px !important;
}

.subscription-plan-body {
  width: 30.5%;
  height: 102px;
  background: #f4f4f4 !important;
  border-radius: 8px !important;
  margin-bottom: 20px;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.subscription-renew-btn {
  float: right;
  border: none;
  width: 90px;
  height: 37px;
  margin-top: 10px;
  background: #ff6750;
  border-radius: 8px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #f2f8fc;
}

.subscription-plan-type {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #455674;
}

.subscription-plan-duration {
  font-family: "poppins", sans-serif;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #465061 !important;
}

.plan-header {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #455674;
  margin-top: 20px;
}

.plan-save-button {
  float: right;
  width: 145px;
  height: 45px;
  background: #455674;
  border-radius: 8px;
  border: none;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #f2f8fc;
}

.modal-content {
  background: #ffffff;
  border-radius: 8px;
}
.close-icon {
  float: right;
  margin-right: 12px;
  cursor: pointer;
}
