@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Medium.ttf");
}
.form-control.is-invalid {
  border-color: #eb5757 !important;
}

.common-form-label {
  width: 43px !important;
  height: 21px !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  white-space: pre !important;
  color: #70b8ff !important;
}

.common-form-input {
  width: 100% !important;
  max-width: 424px !important;
  height: 60px !important;
  background-color: #f4f4f4 !important;
  border-radius: 8px !important;
  box-sizing: border-box;
  border: none !important;
  background-image: none !important;
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5b616b !important;
}

::-webkit-input-placeholder {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #5b616b !important;
}

::-moz-placeholder {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #5b616b !important;
  opacity: 1 !important;
}
::-moz-placeholder-shown {
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  display: flex !important;
  color: #5b616b !important;
  opacity: 1 !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "poppins", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #5b616b !important;
}

.common-invalid-label {
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px;
  color: #d9534f;
  width: 50% !important;
  margin-top: 5px;
  margin-bottom: -9px;
}
